import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import debounce from 'lodash.debounce';
import FieldsTypes from './Types';
import AuthStore from '../../../stores/Auth';
import CatalogApiConsumer from '../../../core/catalog/api';
import FormItem from './FormItem';

const getTypeLabel = type => {
  const fieldTypeDefinition = FieldsTypes(type);
  return fieldTypeDefinition ? fieldTypeDefinition['label'] : type;
};

const getCatalogTreesOptions = async ({ term }) => {
  const { scope } = AuthStore.getScope();
  const trees = await CatalogApiConsumer.searchTrees({
    clientId: scope,
    term,
  });

  const mappedTrees = trees.map(({ kind, name, tree_key }) => {
    let label = `${kind}: ${name}`;

    if (kind === 'cluster') {
      const clusterId = Number(tree_key.split(':')[1]);

      if (clusterId) {
        label = `${kind}: ${name} (#${clusterId})`;
      }
    }

    return {
      label,
      value: tree_key,
    };
  });

  return mappedTrees.sort((a, b) => a[`label`].localeCompare(b[`label`]));
};

const getCatalogTreesTypesOptions = async () => {
  const { scope } = AuthStore.getScope();
  const treesTypes = await CatalogApiConsumer.getTreesTypes(scope);

  return treesTypes
    .map(item => ({ label: item._id, value: item._id }))
    .sort((a, b) => a[`label`].localeCompare(b[`label`]));
};

const FormFieldComponent = props => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(null);
  const [rules, setRules] = useState([]);

  const {
    dataType = 'string',
    defaultValue,
    frontendType,
    options: optionsFromProps,
    required,
    settedValue,
  } = props;

  const fillOptions = () => {
    if (optionsFromProps && Array.isArray(optionsFromProps)) {
      const opts = optionsFromProps.map(option => {
        const [value, label] = option;
        return { label, value };
      });
      setOptions(opts);
    }
  };

  const addValidations = () => {
    const validations = [];

    if (required) {
      validations.push({
        required: true,
        message: 'Campo obrigatório',
      });
    }

    if (frontendType !== 'select' && frontendType !== 'number') {
      if (dataType === 'enum') {
        validations.push({
          type: dataType,
          enum: optionsFromProps.reduce((list, option) => {
            list.push(option[0]);
            return list;
          }, []),
          message: `Selecione uma das opções disponíveis`,
        });
      } else {
        validations.push({
          type: dataType,
          message: `O valor deste campo deve ser do tipo ${getTypeLabel(
            dataType,
          )}`,
        });
      }
    }

    setRules(validations);
  };

  const debounceFetcher = (fetcher, timeout = 500) => {
    const fetchOptions = async value => {
      setFetching(true);
      setOptions([]);

      const opts = await fetcher(value);

      setOptions(opts);
      setFetching(false);
    };

    return debounce(fetchOptions, timeout);
  };

  const debounceOnSearch = () => {
    if (optionsFromProps === 'catalog:trees:types') {
      return debounceFetcher(
        async value => await getCatalogTreesTypesOptions(),
      );
    }

    if (optionsFromProps === 'catalog:trees:nodes') {
      return debounceFetcher(
        async value =>
          await getCatalogTreesOptions({
            term: value,
          }),
      );
    }
  };

  useEffect(() => {
    fillOptions();
    addValidations();
  }, []);

  return (
    <Row className="field-component-row">
      <Col span={24}>
        <FormItem
          {...props}
          defaultValue={settedValue ?? defaultValue}
          fetching={fetching}
          onSearch={debounceOnSearch}
          options={options}
          rules={rules}
        />
      </Col>
    </Row>
  );
};

export default FormFieldComponent;
