import React, { useState } from 'react';
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Col,
  Row,
  InputNumber,
} from 'antd';
import unflatten from '../../../../../../utils/unflatten';

const { Title } = Typography;
const { Option } = Select;

const RegionEditFormComponent = props => {
  const [region, setRegion] = useState(props.region || {});
  const [type, setType] = useState(region.props.region_type);
  const [showPosition, setShowPosition] = useState(
    region.props.region_type === 'normal',
  );
  const [useOverlay, setUseOverlay] = useState(
    region.props?.useOverlay ?? true,
  );

  const handleSubmit = formValues => {
    const values = unflatten(formValues);

    const regionConfig = {
      ...region,
      ...values,
      props: {
        ...values.props,
        isHidden: false,
        inactive_time: values.props.inactive_time || '0',
      },
    };

    if (!regionConfig.placement.operation) {
      regionConfig.placement.operation = 'before';
    }

    props.confirm(regionConfig);
  };

  const handleChangeType = type => {
    if (type !== 'normal') {
      setRegion({
        ...region,
        placement: {
          op: 'after',
          replace: false,
          selector: 'body',
        },
      });
      setShowPosition(false);
      setType(type);
    } else if (type === 'inactive') {
      setRegion({
        ...region,
        props: {
          ...region.props,
          inactive_time: '2000',
        },
      });
      setShowPosition(true);
      setType(type);
    } else {
      setRegion({
        ...region,
        props: {
          ...region.props,
          inactive_time: '0',
        },
      });
      setShowPosition(true);
      setType(type);
    }
  };

  return (
    <Form
      onFinish={handleSubmit}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      size="large"
    >
      <Row className="field-component-row">
        <Col span={24}>
          <Form.Item
            label="Nome"
            name="label"
            rules={[
              {
                required: true,
                message: 'Informe um nome para a área',
              },
            ]}
            initialValue={region.label}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Tipo"
            help="Indique qual o tipo da área de layout desejada (Normal, Exit Intent, Modal de entrada ou Inatividade do usuário)."
            name="props.region_type"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório.',
              },
            ]}
            initialValue={region.props.region_type || 'normal'}
          >
            <Select onChange={handleChangeType}>
              <Option value="normal">Normal</Option>
              <Option value="exitintent">Exit Intent</Option>
              <Option value="entrance">Modal de entrada</Option>
              <Option value="inactive">Inatividade</Option>
            </Select>
          </Form.Item>
        </Col>
        {showPosition === false && (
          <Col span={24}>
            <Form.Item
              label="Template"
              help="Indique se deseja utilizar algum template definido."
              name="props.regionPreset"
              initialValue={region.props.regionPreset || ''}
            >
              <Select>
                <Option value="bn-vitrine">Vitrines</Option>
                <Option value="bn-notification">Notificação</Option>
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>

      {showPosition === true ? (
        <div className="params_group" key="region-position">
          <Col offset="6">
            <Title level={4}>Posicionamento na página</Title>
          </Col>

          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Posição"
                help="Indique a posição da área de layout em relação ao elemento de referência."
                name="placement.operation"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={region.placement.operation}
              >
                <Select>
                  <Option value="before">Antes</Option>
                  <Option value="after">Depois</Option>
                  <Option value="prepend">No início</Option>
                  <Option value="append">No final</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Seletor"
                help="Informe o seletor CSS do elemento de referência."
                name="placement.selector"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={region.placement.selector}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Substituir"
                help="Informe se devemos substituir o elemento de referência ous eu conteúdo da página"
                name="placement.replace"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={(region.placement['replace'] || false).toString()}
              >
                <Select>
                  <Option value="true">Sim</Option>
                  <Option value="false">Não</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="params_group" key="region-position">
          <Col offset="6">
            <Title level={4}>Customizações</Title>
          </Col>
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Posicionamento na tela"
                help="Indique a posição do modal na tela"
                name="props.position"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={String(region.props.position || '0deg')}
              >
                <Select>
                  <Option value="0deg">Centro</Option>
                  <Option value="45deg">Faixa lateral a direita</Option>
                  <Option value="135deg">Faixa lateral a esquerda</Option>
                  <Option value="180deg">Faixa superior</Option>
                  <Option value="90deg">Faixa inferior</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {type === 'inactive' && (
            <Row className="field-component-row">
              <Col span={24}>
                <Form.Item
                  label="Tempo de inatividade"
                  help="Indique o tempo de inatividade necessário para ativação da área de layout."
                  name="props.inactive_time"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório.',
                    },
                  ]}
                  initialValue={String(region.props.inactive_time || '')}
                >
                  <Select>
                    <Option value="2000">2 segundos</Option>
                    <Option value="5000">5 segundos</Option>
                    <Option value="10000">10 segundos</Option>
                    <Option value="15000">15 segundos</Option>
                    <Option value="30000">30 segundos</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Ocultar automaticamente"
                name="props.auto_dismiss"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={String(region.props.auto_dismiss || '')}
              >
                <Select>
                  <Option value="0">Não</Option>
                  <Option value="5">5 segundos</Option>
                  <Option value="10">10 segundos</Option>
                  <Option value="15">15 segundos</Option>
                  <Option value="30">30 segundos</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Largura do Modal"
                help="Dica: Para largura total da tela utilize 100vw"
                name="props.width"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={region.props.width}
              >
                <Input placeholder="Utilize valores em px, rem ou vh" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Altura do Modal"
                help="Dica: Para altura total da tela utilize 100vh"
                name="props.height"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={region.props.height}
              >
                <Input placeholder="Utilize valores em px, rem ou vh" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Cor/Imagem de fundo do modal"
                help="Dica: Você pode utilizar imagens nesse campo, basta inserir url(SEU_LINK)"
                name="props.region_background"
                initialValue={region.props.region_background}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row className="field-component-row">
            <Col span={24}>
              <Form.Item
                label="Aplicar overlay"
                help="Se sim, o modal será fechado ao clicar em qualquer lugar fora dele. Se não, ele só será fechado ao clicar no botão fechar."
                name="props.useOverlay"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório.',
                  },
                ]}
                initialValue={useOverlay}
              >
                <Select onChange={value => setUseOverlay(value)}>
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {useOverlay && (
            <>
              <Row className="field-component-row">
                <Col span={24}>
                  <Form.Item
                    label="Cor de fundo (Overlay)"
                    help="Utilize valores em Hex, RGB ou RGBA"
                    name="props.background_color"
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório.',
                      },
                    ]}
                    initialValue={region.props.background_color}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="field-component-row">
                <Col span={24}>
                  <Form.Item
                    label="Opacidade da cor de fundo (Overlay)"
                    name="props.opacity"
                    rules={[
                      {
                        required: true,
                        message: 'Campo obrigatório.',
                      },
                    ]}
                    initialValue={region.props.opacity}
                  >
                    <InputNumber
                      min={0}
                      max={1}
                      step={0.1}
                      formatter={value => `${value * 100}%`}
                      parser={value =>
                        String(Number(value) * 100).replace('%', '')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}

      <Col span={24}>
        <Form.Item
          label="Classe personalizada"
          name="props.className"
          initialValue={region.props.className || ''}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col offset="9">
        <Button htmlType="submit" type="primary">
          Salvar
        </Button>
      </Col>
    </Form>
  );
};

export default RegionEditFormComponent;
