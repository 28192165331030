import React, { useState } from 'react';

import { Button, Layout, Menu, Modal, PageHeader } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { FolderOutlined, SearchOutlined } from '@ant-design/icons';

import './styles/style.css';

import SearchContent from './Content/Content';
import SearchTestFrame from './Content/Folders/SearchTest';
import IndexationStatus from './Indexation/IndexationStatus';

const { Sider } = Layout;

const SEARCH_SUBMENU_DATA = [
  {
    label: 'Configurações Básicas',
    name: 'basic_configs',
    pagesSummary: [
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Gerais',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Campo de Pesquisa',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Resultados',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Filtros',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Sinônimos',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Regras de Merchandising',
        },
      },
      {
        context: { label: 'Configurações Básicas', name: 'basic_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Regras de Redirecionamento',
        },
      },
    ],
  },
  {
    label: 'Configurações Avançadas',
    name: 'advanced_configs',
    pagesSummary: [
      {
        context: { label: 'Configurações Avançadas', name: 'advanced_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Classificações',
        },
      },
      {
        context: { label: 'Configurações Avançadas', name: 'advanced_configs' },
        props: {
          isDefault: false,
          isHidden: false,
          label: 'Tipos de Busca',
        },
      },
    ],
  },
];

const SearchComponent = () => {
  const [currentFolder, setCurrentFolder] = useState('Configurações Básicas');
  const [currentTab, setCurrentTab] = useState('Gerais');
  const [openSearchFrameModal, setOpenSearchFrameModal] = useState(false);

  const setTab = ({
    context: { label: folderLabel },
    props: { label: tabLabel },
  }) => {
    setCurrentFolder(folderLabel);
    setCurrentTab(tabLabel);
  };

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible={false} theme="light" width={300}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['Gerais']}
            defaultOpenKeys={['basic_configs']}
          >
            <PageHeader backIcon={false} title="Página de Busca" />

            <IndexationStatus />

            <SubMenu
              className="search__submenu-arrow_hidden"
              key={'search-test'}
              onTitleClick={() => setOpenSearchFrameModal(true)}
              title={
                <>
                  <SearchOutlined />
                  <span>Testar Busca</span>
                </>
              }
            />

            {SEARCH_SUBMENU_DATA.map(({ label, name, pagesSummary }) => (
              <SubMenu
                key={name}
                title={
                  <span>
                    <FolderOutlined />
                    <span>{label}</span>
                  </span>
                }
              >
                {pagesSummary?.map(page => (
                  <Menu.Item
                    key={`${page.props.label}`}
                    onClick={() => setTab(page)}
                  >
                    <span>{page.props.label}</span>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>

        <SearchContent currentFolder={currentFolder} currentTab={currentTab} />
      </Layout>

      <Modal
        visible={openSearchFrameModal}
        style={{
          height: '90vh',
        }}
        width="90vw"
        centered
        closable={false}
        destroyOnClose
        footer={
          <Button type="primary" onClick={() => setOpenSearchFrameModal(false)}>
            Fechar
          </Button>
        }
      >
        <SearchTestFrame />
      </Modal>
    </div>
  );
};

export default SearchComponent;
