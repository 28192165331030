import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

import './styles/supportMenu.css';

const SupportMenu = () => {
  const { client_id } = useParams();
  const UTM_MEDIUM = client_id ? 'botao-app' : 'botao-login';

  return (
    <div className="support-menu__container">
      <Button className="support-menu__btn support-btn__success-blog">
        <a
          className="support-menu__link"
          target="_blank"
          href={`https://beonly.notion.site/39306bc747d84481b2b62995ca0c0050?v=e51ca1793ec94765a9300a256d71024b?utm_medium=${UTM_MEDIUM}&utm_campaign=blog-de-sucesso&utm_source=painel-beonly`}
        >
          Acesse o Blog de Sucesso 🚀
        </a>
      </Button>

      <Button className="support-menu__btn support-btn__movidesk">
        <a
          className="support-menu__link"
          target="_blank"
          href="https://beonlyhelp.zendesk.com/"
        >
          Precisa de ajuda? Acesse o Service Desk
        </a>
      </Button>
    </div>
  );
};

export default SupportMenu;
