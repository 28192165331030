export const PRESETS = [
  // Campanhas
  {
    _id: crypto.randomUUID(),
    order: 5,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: destaque de produtos relacionados à campanha.',
    description: 'É muito importante criar contextos relevantes para seus usuários. Recepcione-os na sua loja com produtos aderentes à campanha que os trouxe até aqui.',
    image:
      'https://beonly.notion.site/image/attachment%3Ab0ae67a5-aa9a-4755-9d6a-932d7c1d73da%3AElementos-aplicado_Os_melhores_de_Volta_s_Aulas.png?id=19c843ff-4130-80fc-a000-f8089322b19b&table=block&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Vitrine-destaque-de-produtos-relacionados-campanha-19c843ff413080fca000f8089322b19b',
    element: {
      "type": "showcase",
      "label": "Vitrine: destaque de produtos relacionados à campanha.",
      "props": {
        "label": "Vitrine: destaque de produtos relacionados à campanha.",
        "isPreview": true
      },
      "template": {
        "_id": "96048461-aff2-40d1-a9b1-f62125164cd7",
        "params": {
          "title": "Mais opções para sua Volta às Aulas",
          "target_label": "Veja mais",
          "image_hover": "sim",
          "event_label": "HOMEPAGE vitrine volta as aulas",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "showcase_unique_event": false,
          "cta_buy_show": "nao",
          "cta_buy_label": "Adicionar",
          "cta_buy_style": "primary",
          "cta_buy_params": "seller=1&redirect=true&sc=1",
          "cta_details_show": "sim",
          "cta_details_label": "Ver detalhes",
          "cta_details_style": "primary",
          "stock_unavailable_badge_show": "sim",
          "stock_badge_show": "nao",
          "stock_badge_limit": "",
          "price_discount_badge_show": "sim",
          "price_discount_badge_format": "relative",
          "price_special_badge_show": "nao",
          "price_special_badge_limit": "",
          "free_shipping_badge_price": "",
          "title_tag": "span",
          "container_class": "",
          "container_style": ""
        }
      },
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "CAMPANHA volta aulas 2025",
          "variable": "origin_utm_campaign"
        }
      ],
      "engine": {
        "_id": "202beafa-966f-4cd1-9f7e-fe5713a76033",
        "params": {
          "limit": 16,
          "filters": {
            "include_all": "one",
            "product_selection_key": "none",
            "product_selection_behavior": "none",
            "product_selection_keep_order": "no",
            "attributes": {
              "is_promo": "none",
              "is_new": "none",
              "is_available": "exclude"
            }
          }
        }
      }
    }
  },
  {
    _id: crypto.randomUUID(),
    order: 6,
    contexts: ['cart'],
    taxonomy: ['Call-to-action', 'Interação', 'Fundo'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: crossell no carrinho com itens da campanha.',
    description: 'Aproveite a entrada pela campanha e ofereça produtos relavantes na página do carrinho, aumentando o ticket médio e seu resultado sobre a venda.',
    image:
      'https://beonly.notion.site/image/attachment%3A7c49e643-f970-42bf-bf0e-cb7ee0a7f334%3AElementos-aplicado_No_esquea_nada_para_a_Volta_s_Aulas.png?id=19c843ff-4130-8035-b5ef-c92ae4318dde&table=block&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Vitrine-crossell-no-carrinho-com-itens-da-campanha-19c843ff41308035b5efc92ae4318dde',
    element: {
      "type": "showcase",
      "label": "Vitrine: crossell no carrinho com itens da campanha.",
      "props": {
        "label": "Vitrine: crossell no carrinho com itens da campanha.",
        "isPreview": true
      },
      "template": {
        "params": {
          "title": "Não deixe pra depois: garanta tudo para a Volta às Aulas!",
          "target_label": "Veja mais",
          "image_hover": "sim",
          "event_label": "CARRINHO vitrine impulso volta as aulas",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "showcase_unique_event": false,
          "cta_buy_show": "nao",
          "cta_buy_label": "Adicionar",
          "cta_buy_style": "primary",
          "cta_buy_params": "seller=1&redirect=true&sc=1",
          "cta_details_show": "sim",
          "cta_details_label": "Ver detalhes",
          "cta_details_style": "primary",
          "stock_unavailable_badge_show": "sim",
          "stock_badge_show": "nao",
          "stock_badge_limit": "",
          "price_discount_badge_show": "sim",
          "price_discount_badge_format": "relative",
          "price_special_badge_show": "nao",
          "price_special_badge_limit": "",
          "free_shipping_badge_price": "",
          "title_tag": "span",
          "container_class": "",
          "container_style": ""
        },
        "_id": "96048461-aff2-40d1-a9b1-f62125164cd7"
      },
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "CAMPANHA volta aulas 2025",
          "variable": "origin_utm_campaign"
        },
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "cart",
          "variable": "navigation_page_type"
        }
      ],
      "engine": {
        "_id": "202beafa-966f-4cd1-9f7e-fe5713a76033",
        "params": {
          "filters": {
            "include_all": "one",
            "product_selection_key": "none",
            "product_selection_behavior": "none",
            "product_selection_keep_order": "no",
            "price_range_to": "",
            "attributes": {
              "is_promo": "none",
              "is_new": "none",
              "is_available": "exclude"
            }
          },
          "limit": 16
        }
      }
    }
  },
  {
    _id: crypto.randomUUID(),
    order: 7,
    contexts: ['product'],
    taxonomy: ['Call-to-action', 'Interação', 'Meio'],
    groups: ['vitrine', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Vitrine: produtos relacionados e associados à campanha.',
    description: 'Mantenha seus usuários engajados recomendando produtos semelhantes ao que está vendo, e que participem da mesma campanha. Por exemplo: "Veja mais mochilas para sua Volta ás aulas".',
    image:
      'https://beonly.notion.site/image/attachment%3Ae3a038a7-160c-4fe8-b6a6-0803f25fb334%3AElementos-aplicado_Confira_outras_opes_para_o_seu_Volta_s_Aulas!.png?id=19c843ff-4130-806f-b845-f77944703d54&table=block&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Vitrine-produtos-relacionados-e-associados-campanha-19c843ff4130806fb845f77944703d54?pvs=74',
    element: {
      "props": {
        "label": "Vitrine: produtos relacionados e associados à campanha.",
        "isHidden": false,
        "order": 2
      },
      "engine": {
        "_id": "3fa7fc89-8c36-454c-b721-d98726291805",
        "params": {
          "limit": 16,
          "tree_type": [
            "category"
          ],
          "price_condition": "none",
          "engine": "0f765f85-3a4c-4990-b3dd-d1a13e310b53",
          "filters": {
            "include": [
              "department:games"
            ],
            "include_all": "one",
            "product_selection_key": "none",
            "product_selection_behavior": "none",
            "product_selection_keep_order": "no",
            "attributes": {
              "is_promo": "none",
              "is_new": "none",
              "is_available": "exclude"
            }
          }
        }
      },
      "template": {
        "params": {
          "title": "Veja mais Mochilas para sua Volta às Aulas",
          "target_label": "Veja mais",
          "image_hover": "sim",
          "event_label": "PDP vitrine mochilas volta as aulas",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "showcase_unique_event": false,
          "cta_buy_show": "nao",
          "cta_buy_label": "Adicionar ao Carrinho",
          "cta_buy_style": "primary",
          "cta_buy_params": "seller=1&redirect=true&sc=1",
          "cta_details_show": "sim",
          "cta_details_label": "Ver mais",
          "cta_details_style": "primary",
          "stock_unavailable_badge_show": "sim",
          "stock_badge_show": "nao",
          "stock_badge_limit": "",
          "price_discount_badge_show": "sim",
          "price_discount_badge_format": "relative",
          "price_special_badge_show": "nao",
          "price_special_badge_limit": "",
          "free_shipping_badge_price": "",
          "title_tag": "span",
          "container_class": "",
          "container_style": ""
        },
        "_id": "96048461-aff2-40d1-a9b1-f62125164cd7"
      },
      "type": "showcase",
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "product",
          "variable": "navigation_page_type"
        },
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "CAMPANHA volta aulas 2025",
          "variable": "origin_utm_campaign"
        }
      ],
    }
  },
  {
    _id: crypto.randomUUID(),
    order: 4,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Meio'],
    groups: ['vitrine', 'destaque'],
    group: "",
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Push: cupom por tempo limitado.',
    description: 'Campanhas focadas em conversão precisam focar os usuários na conclusão da compra. Focar na oportunidade e escassez pode acelerar o fechamento enquanto entregam um benefício direto.',
    image:
      'https://beonly.notion.site/image/attachment%3A42a2bf93-9dff-45e2-baec-9276bd005eb6%3AElementos-aplicado_Push_cupom_por_tempo_limitado.png?table=block&id=19c843ff-4130-80fc-8913-e1572c2686cf&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Push-cupom-por-tempo-limitado-19c843ff413080fc8913e1572c2686cf',
    element: {
      "type": "notification",
      "label": "Push: cupom por tempo limitado",
      "props": {
        "label": "Push: cupom por tempo limitado",
        "isPreview": true
      },
      "template": {
        "_id": "f7aa2885-b708-4400-8bb4-7db1f7109e92",
        "params": {
          "layout": "horizontal",
          "screen_position": "180deg",
          "icon": "⏱",
          "use_image": false,
          "headline": "Ofertas especiais de <b>Dias dos pais</b>!",
          "message": "Produtos com até <b>50% de desconto!</b>",
          "cta_label": "Use o cupom<br>DIADOSPAIS",
          "cta_link": "#",
          "auto_dismiss": "0",
          "allow_dismiss": "1",
          "dismiss_style": "times",
          "dismiss_label": "Dispensar",
          "background_color": null,
          "container_class": "",
          "container_style": "",
          "event_label": "PUSH cupom DIADOSPAIS",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "clock_show": "sim",
          "clock_headline": "Essa promoção termina em",
          "clock_timer_type": "dates",
          "clock_interval": "60",
          "clock_start_datetime": "2020-01-01T00:00:00",
          "clock_end_datetime": "2025-02-15T00:00:00",
          "clock_hide_on_end": "true",
          "clock_aggregate_days": "false",
          "clock_with_labels": "sim",
          "custom_notf_body_bg": "#FFFFFF",
          "custom_notf_body_fg": "#333",
          "custom_notf_body_fz": "13px",
          "custom_notf_cta_bg": "#333",
          "custom_notf_cta_fg": "#FFF",
          "custom_clock_bg": "#FF7678",
          "custom_clock_fg": "#000000",
          "custom_clock_fz": "13px"
        }
      },
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "CAMPANHA_disdospais",
          "variable": "origin_utm_campaign"
        }
      ]
    }
  },
  {
    _id: crypto.randomUUID(),
    order: 3,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['push', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Push: boas-vindas.',
    description: 'Utilize os benefícios comerciais que sua loja já tem, como descontos para primeira compra, para converter usuários que chegam por campanhas de aquisição de novos clientes.',
    image:
      'https://beonly.notion.site/image/attachment%3A0abbf9b1-6b32-4df4-9e1b-c04202ec591c%3AElementos-aplicado_Push_boas-vindas.png?table=block&id=19c843ff-4130-80a1-b9ca-fbf598e268cf&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Push-boas-vindas-19c843ff413080a1b9cafbf598e268cf',
    element:
    {
      "type": "notification",
      "label": "Push: boas-vindas",
      "props": {
        "label": "Push: boas-vindas",
        "isPreview": true
      },
      "template": {
        "_id": "f7aa2885-b708-4400-8bb4-7db1f7109e92",
        "params": {
          "layout": "padrao",
          "screen_position": "90deg",
          "icon": "",
          "use_image": false,
          "headline": "<center><b>Bem-vindo!</b></center>",
          "message": "<center>Utilize o cupom <b>BOAS-VINDAS</b> na sua primeira compra e ganhe um presente especial!</center>",
          "cta_label": "Copiar o cupom <b>BOAS_VINDAS</b>",
          "cta_link": "#",
          "auto_dismiss": "0",
          "allow_dismiss": "1",
          "dismiss_style": "text",
          "dismiss_label": "Não quero o presente",
          "background_color": "",
          "container_class": "",
          "container_style": "",
          "event_label": "PUSH boas-vindas",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "clock_show": "nao",
          "clock_headline": "Essas ofertas expiram em:",
          "clock_timer_type": "interval",
          "clock_interval": "60",
          "clock_start_datetime": "2020-01-01T00:00:00",
          "clock_end_datetime": "2019-11-18T00:00:00",
          "clock_hide_on_end": "true",
          "clock_aggregate_days": "false",
          "clock_with_labels": "sim",
          "custom_notf_body_bg": "#FFFFFF",
          "custom_notf_body_fg": "#333",
          "custom_notf_body_fz": "13px",
          "custom_notf_cta_bg": "navy",
          "custom_notf_cta_fg": "#FFF",
          "custom_clock_bg": "#FF7678",
          "custom_clock_fg": "#000000",
          "custom_clock_fz": "13px"
        }
      },
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "BOASVINDAS-2025",
          "variable": "origin_utm_campaign"
        }
      ]
    }
  },
  {
    _id: crypto.randomUUID(),
    order: 1,
    contexts: ['all'],
    taxonomy: ['Call-to-action', 'Interação', 'Topo'],
    groups: ['cta', 'banner', 'destaque'],
    tags: [
      ['yellow', 'Campanhas'],
    ],
    title: 'Banner em faixa: chamada pra LP da campanha.',
    description: 'Usuário podem se desviar da campanha ao longo da sua navegação. Esse banner em faixa é pensado para colocá-lo de volta no caminho desejado.',
    image:
      'https://beonly.notion.site/image/attachment%3A76805426-55ac-4936-94b6-408fc52f8a39%3AElementos-aplicado_Banner_em_faixa-_chamada_pra_LP_da_campanha.png?table=block&id=19c843ff-4130-8088-823a-fa9764a6dd75&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
    helperUrl:
      'https://beonly.notion.site/Banner-em-faixa-chamada-pra-LP-da-campanha-19c843ff41308088823afa9764a6dd75',
    element: {
      "type": "banner",
      "label": "Banner em faixa: chamada pra LP da campanha.",
      "props": {
        "label": "Banner em faixa: chamada pra LP da campanha.",
        "isPreview": true
      },
      "template": {
        "_id": "cf7f92ca-4e9d-40eb-af76-f92a3bb3da4e",
        "params": {
          "layout": "compacto",
          "banner_headline": "Os melhores preços de Volta às Aulas estão aqui!",
          "cta_show": "sim",
          "cta_label": "Confira todas as opções!",
          "cta_link": "https://...",
          "event_label": "FAIXA lp volta aulas",
          "dispatch_ga_events": true,
          "session_unique_event": false,
          "showcase_unique_event": false,
          "clock_show": "nao",
          "clock_headline": "Essas ofertas expiram em:",
          "clock_timer_type": "interval",
          "clock_interval": "60",
          "clock_start_datetime": "2020-01-01T00:00:00",
          "clock_end_datetime": "2020-12-31T00:00:00",
          "clock_hide_on_end": "true",
          "clock_aggregate_days": "false",
          "clock_with_labels": "sim",
          "custom_banner_bg": "#333",
          "custom_banner_fg": "#FFF",
          "custom_banner_justify": "space-around",
          "custom_banner_maxwidth": "100%",
          "custom_headline_fg": "#FFF",
          "custom_headline_align": "center",
          "custom_headline_fz": "1.5em",
          "custom_cta_bg": "transparent",
          "custom_cta_fg": "#FFF",
          "custom_cta_fz": "1em",
          "custom_cta_border": "2px solid",
          "custom_clock_bg": "transparent",
          "custom_clock_fg": "#FFF",
          "custom_clock_fz": "14px",
          "container_class": "",
          "container_style": ""
        }
      },
      "segmentation": [
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "homepage",
          "variable": "navigation_page_type"
        },
        {
          "_id": crypto.randomUUID(),
          "created_at": new Date(),
          "updated_at": new Date(),
          "operator": "equals",
          "expected": "CAMPANHA volta aulas 2025",
          "variable": "origin_utm_campaign"
        }
      ]
    }
  },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 2,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Interação', 'Topo'],
  //   groups: ['banner', 'destaque'],
  //   tags: [
  //     ['yellow', 'Campanhas'],
  //   ],
  //   title: 'Banner em slide: personalize os banners para a campanha.',
  //   description: 'Contexto é tudo. Personalize sua loja com banners especiais para a campanha que trouxe os usuários para seu site, ampliando o engajamento e retenção deles na sua loja.',
  //   image:
  //     'https://beonly.notion.site/image/attachment%3Aec4e74c8-dcd7-4fca-bf58-1fa0bcd9806a%3AElementos-aplicado_anner_em_slide-_personalize_os_banners_para_a_campanha.png?table=block&id=19c843ff-4130-809d-9841-c8aedf740eac&spaceId=31da8555-54b1-49c9-b996-fc0ad6c1759b&width=1920&userId=&cache=v2',
  //   helperUrl:
  //     'https://beonly.notion.site/Banner-em-slide-personalize-os-banners-para-a-campanha-19c843ff4130809d9841c8aedf740eac',
  //   element: {
  //     "type": "banner",
  //     "label": "Banner em slide: personalize os banners para a campanha.",
  //     "props": {
  //       "label": "Banner em slide: personalize os banners para a campanha.",
  //       "isPreview": true
  //     },
  //     "template": {
  //       "_id": "783e8e9a-9570-4aa1-b333-15f895cd34ff",
  //       "params": {
  //         "banner_alt": "Descreve brevemente seu banner, a oportunidade contida nele.",
  //         "cta_enable": "sim",
  //         "cta_link": "https://",
  //         "cta_target": "_self",
  //         "container_class": "",
  //         "container_style": "",
  //         "mobile_enable": "sim",
  //         "mobile_image_src": "",
  //         "mobile_image_width": 1200,
  //         "mobile_image_height": 450,
  //         "desktop_enable": "sim",
  //         "desktop_image_src": "",
  //         "desktop_image_width": 400,
  //         "desktop_image_height": 250,
  //         "event_label": "BANNER volta aulas slide 01",
  //         "dispatch_ga_events": true,
  //         "session_unique_event": false
  //       }
  //     },
  //     "segmentation": [
  //       {
  //         "_id": crypto.randomUUID(),
  //         "created_at": new Date(),
  //         "updated_at": new Date(),
  //         "operator": "equals",
  //         "expected": "CAMPANHA volta aulas 2025",
  //         "variable": "origin_utm_campaign"
  //       }
  //     ]
  //   }
  // },

  // CTAs
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Interação'],
  //   groups: ['cta', 'banner', 'temporizador'],
  //   tags: [
  //     ['#1a1245', 'Exemplo'],
  //     ['#000', 'Black Friday'],
  //   ],
  //   title: 'Banner em faixa com temporizador e call-to-action',
  //   description: 'Foque a atenção dos usuários no carrinho de compras prevenindo abandono ou acelerando o tempo de fechamento.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['cta', 'temporizador'],
  //   tags: [],
  //   title: 'Temporizador com call-to-action independente',
  //   description: 'Adicione gatilhos de urgência em elementos que já existem na sua página.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['vitrine', 'temporizador'],
  //   tags: [],
  //   title: 'Temporizador associado a vitrines',
  //   description: 'Utilize um temporizador em formato de faixa para adicionar urgência em vitrines.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Interação'],
  //   groups: ['cta', 'banner'],
  //   tags: [],
  //   title: 'Banner em Faixa com call-to-action para Landing Page',
  //   description: 'Conduza seus usuários para páginas especiais como Sale, Lançamentos, etc.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Interação'],
  //   groups: ['cta', 'banner', 'temporizador'],
  //   tags: [],
  //   title: 'Banner em Faixa com temporizador em destaque.',
  //   description: 'Conduza seus usuários para páginas especiais como Sale, Lançamentos, etc.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },

  // // Pushes
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Push', 'Call-to-action', 'Interação', 'Carrinho'],
  //   groups: ['cta', 'push', 'progress'],
  //   tags: [],
  //   title: 'Push: com contador de subtotal do carrinho.',
  //   description: 'Ofereça benefícios baseados no subtotal do carrinho de compras, como Frete Grátis, descontos ou brindes.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: com benefícios por tempo limitado.',
  //   description: 'Estimule os usuários com a urgência da promoção de itens selecionados.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: com imagem em destaque.',
  //   description: 'Adicione seu criativo na estrutura do Push básico.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: ofertando um benefício para carrinhos selecionados.',
  //   description: 'Descontos ou outros estímulos comerciais direcionados para carrinhos que atendam a uma ou mais regras, como valor mínimo.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: com Cupom de desconto para carrinhos selecionados',
  //   description: 'Um push com um comportamento especial: clique para copiar o cupom.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: com oferta de Upsell.',
  //   description: 'Sinalize os benefícios de uma compra maior em produtos que possuam ofertas de kits ou benefícios para compra em quantidade.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Avançado', 'Call-to-action', 'Estímulo', 'Interação', 'Temporizadores'],
  //   groups: ['push'],
  //   tags: [],
  //   title: 'Push: com ofertas progressivas.',
  //   description: 'Crie uma cadeia de benefícios progressivos com pushes encadeados.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },

  // // Vitrines
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Os seus favoritos.',
  //   description: 'Utilize a recomendação Histórido do Usuário para destacar seus produtos favoritos e reduzir o tempo de comparação nas Páginas de Produto.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Não perca de vista.',
  //   description: 'Mantenha os usuários conectados com seu histórico de navegação na Homepage, Catálogo e outras páginas de topo de funil.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Não esqueça deles!',
  //   description: 'Aumente seu ticket médio recomendando produtos relevantes para a navegação do usuário na página do carrinho.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Recomendados para Você.',
  //   description: 'Crie uma recomendação especial para cada produto visto pelo usuário e mantenha o engajamento em todas as páginas do site.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Outras opções.',
  //   description: 'Recomende produtos substitutos nas Páginas de Produto para prevenir o abandono e aumentar suas conversões.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Combina este produto com...',
  //   description: 'Uma vitrine feita sob medida para aumentar seu ticket médio sugerindo complementos para o produto que o usuário está vendo.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Quem comprou, comprou também.',
  //   description: 'Crie variações desse tipo de vitrine combinando atributos do produto sendo visto com filtros de produtos complementares.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Selecionados para você.',
  //   description: 'Recomende as melhores ofertas do seu catálogo combinando atributos de interesse do usuário.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Sale!',
  //   description: 'Destaque suas promoções com diversos filtros e condições. Explore variações com Lançamentos, Mais vendidos, etc.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // },
  // {
  //   _id: crypto.randomUUID(),
  //   order: 1,
  //   contexts: ['all'],
  //   taxonomy: ['Vitrines', 'Ofertas', 'Histórico'],
  //   groups: ['vitrine'],
  //   tags: [],
  //   title: 'Vitrine: Destaques da Categoria.',
  //   description: 'Personalize e destaque os melhores produtos das suas páginas de categoria.',
  //   image:
  //     'https://cdn.usebeon.io/tenant/da05baec-9d3a-4b94-8e46-be236f245bcc/images/2eb1a2f0-aa87-43eb-baea-3a148a204d08/FaixaTopoComCTA.jpg',
  //   helperUrl:
  //     'https://beonly.notion.site/Faixa-no-topo-com-CTA-e-Cron-metro-para-finalizar-a-compra-d45360b5d4fb4b8cb32ca310a462376f',
  // }
];

export const PRESETS_GROUPS = [
  {
    group: "destaque",
    label: "Destaques",
    order: 1,
    section: "basic_components"
  },
  {
    group: "vitrine",
    label: "Vitrines",
    order: 10,
    section: "basic_components"
  },
  {
    group: "push",
    label: "Pushes (notificações)",
    order: 20,
    section: "basic_components"
  },
  {
    group: "cta",
    label: "Call-to-actions",
    order: 30,
    section: "basic_components"
  },
  // {
  //   group: "banner",
  //   label: "Banners",
  //   order: 40,
  //   section: "basic_components"
  // },
  // {
  //   group: "temporizador",
  //   label: "Temporizadores",
  //   order: 50,
  //   section: "basic_components"
  // },
  // {
  //   group: "progress",
  //   label: "Progresso",
  //   order: 60,
  //   section: "basic_components"
  // }
]