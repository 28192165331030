import React, { useState } from 'react';
import { Form, Input, message, Upload } from 'antd';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const ECMS_API_BASE_URL = process.env.REACT_APP_ECMS_API_URL;

const FileItem = ({ props }) => {
  const { defaultValue, element, form, label, name } = props;
  const { client_id } = useParams();
  const [fileList, setFileList] = useState(
    defaultValue ? [{ url: defaultValue, uid: '-1', name: 'image' }] : [],
  );

  const deleteImage = async imageParams => {
    const payload = JSON.stringify({ imageParams });
    await fetch(`${ECMS_API_BASE_URL}/presets/image/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    });
  };

  const deleteImgFromBucket = async file => {
    try {
      const parts = file.url.split('/').filter(part => part !== '');
      const imageParams = parts.slice(-4).join('/');
      await deleteImage(imageParams);

      setFileList([]);
      form.setFieldsValue({ [name]: '' });
    } catch (e) {
      console.error('Delete image from bucket failed with', e);
    }
  };

  const uploadImage = async formData => {
    const response = await fetch(
      `${ECMS_API_BASE_URL}/presets/image/${client_id}/${element._id}`,
      {
        method: 'POST',
        body: formData,
      },
    );
    const { url } = await response.json();
    return url;
  };

  const handleUpload = async options => {
    try {
      const { file } = options;
      const formData = new FormData();
      formData.append('image', file);
      const url = await uploadImage(formData);

      const newFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url,
      };

      setFileList([newFile]);
      form.setFieldsValue({ [name]: url });
    } catch (e) {
      message.error(
        'Ocorreu um erro ao subir a imagem. Por favor, tente novamente',
      );
      console.error('Handle upload failed with', e);
    }
  };

  return (
    <>
      <Form.Item name={name} initialValue={defaultValue ?? ''} hidden>
        <Input />
      </Form.Item>

      <Form.Item label={label}>
        <Upload
          name="file"
          listType="picture-card"
          fileList={fileList}
          customRequest={handleUpload}
          onRemove={deleteImgFromBucket}
          maxCount={1}
          accept=".jpg, .jpeg, .png, .gif"
        >
          {fileList.length === 0 && (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default FileItem;
