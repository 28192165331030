import React from 'react';
import { Col, Row, Tag, Typography } from 'antd';

import RedirectRuleEdit from './Actions/Edit';
import RedirectRuleDelete from './Actions/Delete';

const { Text } = Typography;

const RedirectRuleComponent = ({ deleteRule, editRule, rule }) => {
  const { _id, label, url, terms, op, target, is_active: isActive } = rule;

  return (
    <Row
      className="page-element-row"
      style={{ alignItems: 'center', backgroundColor: 'white' }}
    >

      <Col span={1}>
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Ativo' : 'Inativo'}
        </Tag>
      </Col>

      <Col span={6}>
        <Text
          style={{ width: "80%" }}
          ellipsis={{ tooltip: '...' }}>{label}</Text>
      </Col>

      <Col span={6}>
        <Text
          style={{ width: "80%" }}
          ellipsis={{ tooltip: '...' }}>{url}</Text>
      </Col>

      <Col span={9}>
        {terms?.map(term => (
          <Tag
            color="blue"
            style={{
              maxWidth: '450px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {term}
          </Tag>
        ))}
      </Col>

      <Col span={1}>
        <RedirectRuleEdit onEdit={() => editRule(_id)} />
      </Col>

      <Col span={1}>
        <RedirectRuleDelete onDelete={() => deleteRule(rule)} />
      </Col>
    </Row>
  );
};

export default RedirectRuleComponent;
