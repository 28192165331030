import createConsumer from '../../rest/createConsumer';
import { PRESETS, PRESETS_GROUPS } from './presets';

const PresetsApiStaticConsumer = createConsumer(() => {
  const getPresets = () => {
    return PRESETS;
  };
  
  const getPresetsGroups = () => {
    return PRESETS_GROUPS;
  };

  return Object.freeze({
    getPresets, getPresetsGroups
  });
});

export default PresetsApiStaticConsumer;
