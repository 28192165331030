import React from 'react';
import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const RedirectRulesHeader = () => {
  return (
    <Row
      className="page-element-row"
      style={{
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Col span={7}>
        <Title level={5}>Título</Title>
      </Col>

      <Col span={6}>
        <Title level={5}>URL</Title>
      </Col>

      <Col span={8}>
        <Title level={5}>Palavras</Title>
      </Col>
    </Row>
  );
};

export default RedirectRulesHeader;
